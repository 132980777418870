import React from "react"

import logo from "../images/logo.png"
import SectionHeader from "./section-header"

const Content = () => (
  <div style={{ padding: "1rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="A 1000+ substitutions for common baking and cooking ingredients!"
    />
    <content
                       className="multiGrid"
    >
      <div style={{ textAlign: "left" }}>
      • Choose between Imperial and Metric systems<br />
      • Swap ingredient amounts on the fly<br />
      • Works offline!<br />
      • Change recipes to fit any dietary, religious or health concern<br />
      • Vegan, vegetarian, keto, paleo, halal and kosher diets; low carb, low fat and gluten-free alternatives<br />
      • Available in Dark Mode<br /><br />

Whether you're cooking for someone with special religious or dietary needs, run out of one of the ingredients in the recipe, or just trying to eat healthier, this app has got you covered.<br /><br />

Reasons you'll love FoodSubs:<br />
• Enter your original ingredient amount and the app will calculate substitution ingredient amount for you automatically<br />
• Add ingredients to favorites for easy access<br />
• Flexible search with diet filters<br />
• Alternate ingredient names (and they are also searchable!)<br />
• Tips and interesting information about your ingredients<br />
      </div>
      <div>
        <img src={logo} alt="a blank card floating over colorful graphics" className="logoImage" />
      </div>
    </content>
  </div>
)

export default Content
